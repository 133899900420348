import './App.scss';
import Home from './views/Home';
import { Routes, Route, useNavigate, Outlet, Navigate } from 'react-router-dom'
import About from './views/About';
import Service from './views/Service';
import GkcToken from './views/GkcToken';
import UserDashboard from './userdashboard/views/UserDashboard';
import SignUp from './userdashboard/views/SignUp';
import SignIn from './userdashboard/views/SignIn';
import ForgotPassword from './userdashboard/views/ForgotPassword';
import SentOtp from './userdashboard/views/SentOtp';
import UserBuy from './userdashboard/views/UserBuy';
import UserProfile from './userdashboard/views/UserProfile';
import UserAccountSetting from './userdashboard/views/UserAccountSetting';
import ClaimToken from './views/ClaimToken';
import { useWeb3React } from '@web3-react/core';
import { MessageBox } from './components/MessageBox';
import { useEffect } from 'react';
import ResetPassword from './userdashboard/views/ResetPassword';
import AdminProfile from './admindashboard/views/AdminProfile';
import AdminAccountSetting from './admindashboard/views/AdminAccountSetting';
import AdminBuy from './admindashboard/views/AdminBuy';

import { AIRDROP_CONTRACT, loadContract } from './components/constant';
import airdropAbi from './abis/airdropAbi.json'
import { useState } from 'react';
import useAuth from './components/connectWallet/useAuth';
import { injected, walletconnect } from './components/connectWallet/connectors';
import UsersList from './admindashboard/views/UsersList';
import AdminDashboard from './admindashboard/views/AdminDashboard';
import DirectTransferUsers from './admindashboard/views/DirectTransferUsers';

function App() {

  const [adminAddress, setAdminAddress] = useState('')
  const { account, active, library } = useWeb3React()
  const navigate = useNavigate();
  const { login } = useAuth()

  // const logout = () => {
  //   if (account != sessionStorage.getItem('walletaddress')) {
  //     sessionStorage.clear();
  //     navigate('/signin');
  //     MessageBox('error', "Invalid wallet, Please change your wallet and try again.");
  //   }
  // }

  useEffect(() => {
    if (sessionStorage.getItem('connect') && sessionStorage.getItem('walletname')) {
      if (sessionStorage.getItem('walletname') === 'metamask') {
        login(injected)
      }
      if (sessionStorage.getItem('walletname') === 'walletconnect') {
        login(walletconnect)
      }
    }
  }, [])

  // useEffect(() => {
  //   if (account && active && (sessionStorage.getItem('walletaddress'))) {
  //     logout();
  //   }
  // }, [account, active])

  const getAdminAddress = async () => {
    try {
      const airdropContract = loadContract(library.provider, airdropAbi, AIRDROP_CONTRACT);
      const admin = await airdropContract.methods.admin().call();
      setAdminAddress(admin);
    } catch (e) {
      console.log("Error.Contract-admin", e);
    }
  }

  useEffect(() => {
    if (account) {
      getAdminAddress()
    }
  }, [account])

  const AdminRoute = () => {
    if (sessionStorage.getItem('isAdmin')) {
      return <Outlet />
    } else {
      return <Navigate to={'/signin'} />
    }
    // return <Outlet />
  }

  const PrivateRoute = () => {
    if (sessionStorage.getItem('email')) {
      return <Outlet />
    } else {
      return <Navigate to={'/signin'} />
    }
    // return <Outlet />
  }

  return (

    <div className="App">
      <Routes>
        <Route exact path='/' element={<Home />} />
        <Route exact path='/about' element={<About />} />
        <Route exact path='/service' element={<Service />} />
        <Route exact path='/gkc-token' element={<GkcToken />} />
        <Route exact path='/claim-token' element={<ClaimToken />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/sent-otp" element={<SentOtp />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route element={<PrivateRoute />}>
          <Route path="/user-dashboard" element={<UserDashboard />} />
          <Route path="/user-buy" element={<UserBuy />} />
          <Route path="/user-profile" element={<UserProfile />} />
          <Route path="/user-account-setting" element={<UserAccountSetting />} />
        </Route>
        <Route element={<AdminRoute />}>
          <Route path="/admin-dashboard" element={<AdminDashboard />} />
          <Route path="/admin-profile" element={<AdminProfile />} />
          <Route path="/admin-account-setting" element={<AdminAccountSetting />} />
          {/* <Route path="/admin-dashboard#BuyGKC_token" element={<AdminDashboard />} /> */}
          <Route path="/users" element={<UsersList />} />
          <Route path="/direct-transfer" element={<DirectTransferUsers />} />
        </Route>
      </Routes>
      <Routes>
      </Routes>
    </div>
  );
}

export default App;

import React, { useEffect, useState } from "react";
import AdminDashBoardLayout from "../layout/AdminDashBoardLayout";
import {
  Button,
  Col,
  Container,
  Modal,
  Row,
  Table,
  Form,
} from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { BiCopy } from "react-icons/bi";
import { BsFacebook, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import {
  ADMIN_ADDRESS,
  AIRDROP_CONTRACT,
  DOMAIN_URL,
  REF_URL,
  ROOT_URL,
  convertPrice,
  getShortAddress,
  loadContract,
  rpcUrl,
} from "../../components/constant";
import { useWeb3React } from "@web3-react/core";
import useCopy from "../../useCopy";
import axios from "axios";
import Web3 from "web3";
import airdropAbi from "../../abis/airdropAbi.json";
import { MessageBox } from "../../components/MessageBox";
import truncateEthAddress from "truncate-eth-address";
import { FacebookMessengerShareButton } from "react-share";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const UsersList = () => {
  const [userData, setUserData] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageCount, setPageCount] = useState(0);
  const [pageData, setPageData] = useState(null);
  const [modalShow, setModalShow] = useState(false);
  const [clickedIndex, setClickedIndex] = useState(null)
  const [modalData, setModalData] = useState({
    alloted: null,
    reg_date: null,
    airdrop: null,
    purchased: null,
    total_refer: null,
    ref_lvl1: null,
    ref_lvl2: null,
    minted: null,
    minted_bal: null,
    user_ref: null,
  });
  const itemsPerPage = 8;
  const { account, active } = useWeb3React();
  const { copyText, copied } = useCopy();
  const [searchInput, setSearchInput] = useState("");
  const [tempData, setTempData] = useState(null);

  useEffect(() => {
    if (userData) {
      const lastIndex = currentPage * itemsPerPage;
      const pageCount = Math.ceil(userData.length / itemsPerPage);
      setPageCount(pageCount);
      const paginatedData = userData.slice(lastIndex - itemsPerPage, lastIndex);
      setPageData(paginatedData);
    }
  }, [userData, currentPage]);

  const getUserData = async () => {
    const res_admin_data = await axios.post(`${ROOT_URL}/Get_Admin_Data`);
    if (res_admin_data.data.code === 200) {
      setUserData(res_admin_data.data.data);
      setTempData(res_admin_data.data.data);
    } else {
      setUserData([]);
      setTempData([]);
    }
  };

  useEffect(() => {
    getUserData();
  }, []);

  const handleView = async (address, reg_date) => {
    // if (!account) {
    //   MessageBox("error", "Please Connect Your Wallet");
    //   return;
    // }
    // if (
    //   account.toLocaleLowerCase() !==
    //   sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    // ) {
    //   MessageBox(
    //     "error",
    //     `Wrong address please connect with ${sessionStorage.getItem(
    //       "walletaddress"
    //     )}`
    //   );
    //   return;
    // }
    setModalShow(true);
    const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    const airdropContract = loadContract(
      httpProvider,
      airdropAbi,
      AIRDROP_CONTRACT
    );
    var tx_airdrop = await airdropContract.methods
      .usersTotalTokenAirdrop(address)
      .call();
    var tx_purchased = await airdropContract.methods
      .usersTotalTokenPurchased(address)
      .call();
    var tx_ref_lvl1 = await airdropContract.methods
      .usersLevelOneReferralEarning(address)
      .call();
    var tx_ref_lvl2 = await airdropContract.methods
      .usersLevelTwoReferralEarning(address)
      .call();
    var tx_minted_bal = await airdropContract.methods
      .getUserTokensAvailableForMinting(address)
      .call();
    var tx_raffral = await airdropContract.methods.userReferrer(address).call();
    setModalData({
      alloted:
        (tx_airdrop > 0 ? parseFloat(convertPrice(tx_airdrop, 18)) : 0) +
        (tx_purchased > 0 ? parseFloat(convertPrice(tx_purchased, 18)) : 0) +
        (tx_ref_lvl1 > 0 ? parseFloat(convertPrice(tx_ref_lvl1, 18)) : 0) +
        (tx_ref_lvl2 > 0 ? parseFloat(convertPrice(tx_ref_lvl2, 18)) : 0),
      reg_date: reg_date ? reg_date : "-",
      airdrop: tx_airdrop ? convertPrice(tx_airdrop, 18) : 0,
      purchased: tx_purchased ? convertPrice(tx_purchased, 18) : 0,
      total_refer:
        (tx_ref_lvl1 > 0 ? parseFloat(convertPrice(tx_ref_lvl1, 18)) : 0) +
        (tx_ref_lvl2 > 0 ? parseFloat(convertPrice(tx_ref_lvl2, 18)) : 0),
      ref_lvl1: tx_ref_lvl1 ? convertPrice(tx_ref_lvl1, 18) : 0,
      ref_lvl2: tx_ref_lvl2 ? convertPrice(tx_ref_lvl2, 18) : 0,
      minted: tx_minted_bal ? convertPrice(tx_minted_bal, 18) : 0,
      minted_bal: tx_minted_bal ? convertPrice(tx_minted_bal, 18) : 0,
      user_ref: tx_raffral ? tx_raffral : "0x00",
    });
  };

  useEffect(() => {
    if (searchInput && tempData) {
      const searchData = tempData.filter(function (event) {
        return (
          event.name.toLocaleLowerCase().includes(searchInput.toLowerCase()) ||
          event.email.toLocaleLowerCase().includes(searchInput.toLowerCase()) ||
          event.mobile
            .toLocaleLowerCase()
            .includes(searchInput.toLowerCase()) ||
          event.walletaddress
            .toLocaleLowerCase()
            .includes(searchInput.toLowerCase()) ||
          event.refaddess
            .toLocaleLowerCase()
            .includes(searchInput.toLowerCase())
        );
      });
      setCurrentPage(1);
      setUserData(searchData);
    } else {
      setCurrentPage(1);
      setUserData(tempData);
    }
  }, [searchInput, tempData])

  function exportToExcel(jsonData) {
    // if (!account) {
    //   MessageBox("error", "Please Connect Your Wallet");
    //   return;
    // }
    // if (
    //   account.toLocaleLowerCase() !==
    //   sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    // ) {
    //   MessageBox(
    //     "error",
    //     `Wrong address please connect with ${sessionStorage.getItem(
    //       "walletaddress"
    //     )}`
    //   );
    //   return;
    // }
    if (jsonData.length) {
      // Create a new workbook and worksheet
      const workbook = XLSX.utils.book_new();
      const worksheet = XLSX.utils.json_to_sheet(jsonData);

      // Add the worksheet to the workbook
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

      // Generate an Excel file and save it
      const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      const data = new Blob([excelBuffer], {
        type: "application/octet-stream",
      });
      saveAs(data, "GKC_Users_Data.xlsx");
    } else {
      MessageBox("error", "There is no data available for export");
    }
  }

  const deleteUser = (walletAddress) => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet");
      return;
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect with ${sessionStorage.getItem(
          "walletaddress"
        )}`
      );
      return;
    }
    axios({
      method: 'post',
      url: `${ROOT_URL}/Delete_User`,
      data: {
        walletaddress: walletAddress,
      }
    }).then(res => {
      if (res.data.code == 200) {
        getUserData()
        MessageBox('success', res.data.message);
      } else {
        MessageBox('error', res.data.message);
      }
    }).catch(err => {
      console.log('Error.delete-user', err);
    });
  }

  return (
    <>
      <AdminDashBoardLayout>
        <section className="py-5">
          <Container>
            <Row className="d-flex justify-content-end align-items-center">
              <Form.Group
                as={Col}
                xs={12}
                md={6}
                className="mb-4 d-flex align-items-center"
              >
                <div className="w-100 me-2">
                  <Form.Control
                    type="text"
                    placeholder="Search"
                    value={searchInput}
                    onChange={(event) => setSearchInput(event.target.value)}
                  />
                </div>
                <Button
                  className="rounded btn-light text-black fw-500 export_btn"
                  onClick={() => {
                    exportToExcel(tempData ? tempData : []);
                  }}
                >
                  Export
                </Button>
              </Form.Group>
            </Row>
            <Row>
              <Col xs={12}>
                <Table responsive className="text-white mb-0 users-table">
                  <thead>
                    <tr>
                      <th scope="col" className="py-2 px-3">
                        Date
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Name
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Email Id
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Mobile
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Wallet Address
                      </th>
                      <th scope="col" className="py-2 px-3">
                        Ref. Address
                      </th>
                      <th scope="col" className="py-2 px-3 text-center">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {pageData ? (
                      pageData.length ? (
                        pageData.map((i, index) => (
                          <tr>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {i.ondate}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {i.name}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {i.email}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {i.mobile}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {getShortAddress(i.walletaddress)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => { setClickedIndex(index); copyText(i.walletaddress); }} />}
                            </td>
                            <td className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              {getShortAddress(i.refaddess)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => { setClickedIndex(index); copyText(i.refaddess) }} />}
                            </td>
                            <td className="d-flex text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3">
                              <Button
                                variant="info"
                                className="w-100 rounded-pill text-white fw-700 px-4"
                                onClick={() => {
                                  handleView(i.walletaddress, i.ondate);
                                }}
                              >
                                View
                              </Button>
                              {/* <Button
                                variant="danger"
                                className="w-100 rounded-pill text-white fw-700 px-4 ms-2"
                                onClick={() => {
                                  deleteUser(i.walletaddress);
                                }}
                              >
                                Delete
                              </Button> */}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr className="text-white text-center">
                          <td
                            colSpan={6}
                            className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3"
                          >
                            NO DATA FOUND
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr className="text-white text-center">
                        <td
                          colSpan={6}
                          className="text-logan fs-16 fw-400 font-roboto align-middle py-2 px-3"
                        >
                          Loading...
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </Col>
              <Col xs={12} className="d-flex justify-content-end">
                {pageData && pageData.length ? (
                  <ReactPaginate
                    pageCount={pageCount}
                    onPageChange={(event) => setCurrentPage(event.selected + 1)}
                    previousLabel={"Prev"}
                    nextLabel={"Next"}
                    breakLabel={"..."}
                    containerClassName={"pagination-container"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    activeClassName={"activepage"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                    breakClassName={"page-item"}
                    breakLinkClassName={"page-link"}
                    className="ps-0 my-5"
                    forcePage={currentPage - 1}
                  />
                ) : (
                  ""
                )}
              </Col>
            </Row>
          </Container>
        </section>

        <Modal
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={modalShow}
          onHide={() => setModalShow(false)}
          className="text-black"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              User Detail
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="py-1">
              Total Token Allotted :{" "}
              {modalData.alloted != null ? modalData.alloted + " GKC " : "Loading..."}
            </div>
            <div className="py-1">
              Registration Date :{" "}
              {modalData.reg_date != null ? modalData.reg_date : "Loading..."}
            </div>
            <div className="py-1">
              Airdrop Token :{" "}
              {modalData.airdrop != null ? modalData.airdrop + " GKC" : "Loading..."}
            </div>
            <div className="py-1">
              Purchased Tokens :{" "}
              {modalData.purchased != null
                ? modalData.purchased + " GKC "
                : "Loading..."}
            </div>
            <div className="py-1">
              Total Refer :{" "}
              {modalData.total_refer != null
                ? modalData.total_refer + " GKC "
                : "Loading..."}
            </div>
            <div className="py-1">
              Refer to Level 1 :{" "}
              {modalData.ref_lvl1 != null ? modalData.ref_lvl1 + " GKC " : "Loading..."}
            </div>
            <div className="py-1">
              Refer to Level 2 :{" "}
              {modalData.ref_lvl2 != null ? modalData.ref_lvl2 + " GKC " : "Loading..."}
            </div>
            <div className="py-1">
              Total Minted :{" "}
              {modalData.minted != null ? modalData.minted + " GKC" : "Loading..."}
            </div>
            <div className="py-1">
              Total Minted Balance :{" "}
              {modalData.minted_bal != null
                ? modalData.minted_bal + " GKC"
                : "Loading..."}
            </div>
            <div className="py-1">
              User Referral :{" "}
              {modalData.user_ref != null ? <span>{modalData.user_ref} {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(modalData.user_ref)} />}</span> : "Loading..."}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="secondary"
              className="rounded-pill px-4 text-white fw-700"
              onClick={() => setModalShow(false)}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </AdminDashBoardLayout>
    </>
  );
};

export default UsersList;

import React from "react";
import Footer from "../layout/Footer";
import Header from "../layout/Header.";
import { Row, Container, Col, Card, Image } from "react-bootstrap";
import GT1 from "../assets/images/Tokanamic.png";
import GT2 from "../assets/images/GKCToken-two.png";
import GT3 from "../assets/images/GKCToken-three.png";
import Airdrop from "../assets/images/airdrop.png";
import { useState } from "react";
import {
  AIRDROP_CONTRACT,
  TOKEN_CONTRACT,
  convertPrice,
  loadContract,
  rpcUrl,
  scanerUrl,
} from "../components/constant";
import airdropAbi from "../abis/airdropAbi.json";
import tokenAbi from "../abis/tokenAbi.json";
import Web3 from "web3";
import { useEffect } from "react";
import truncateEthAddress from "truncate-eth-address";
import { Link } from "react-router-dom";
import ProgressBarc from "../components/ProgressBarc";
import useCopy from "../useCopy";
import { BiCopy } from "react-icons/bi";

const GkcToken = () => {
  const [tokenContractAddress, setTokenContractAddress] = useState("");
  const [tokenPriceUsd, setTokenPriceUsd] = useState("");
  const [totalSupply, setTotalSupply] = useState("");
  const [tokenPrice, setTokenPrice] = useState("");
  const [totalTokensAllowted, setTotalTokensAlloetwd] = useState(0);
  const { copied, copyText } = useCopy()

  const getTokenData = async () => {
    try {
      const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl));
      const airdropContract = loadContract(
        httpProvider,
        airdropAbi,
        AIRDROP_CONTRACT
      );
      const tokenContract = loadContract(
        httpProvider,
        tokenAbi,
        TOKEN_CONTRACT
      );
      const tokenAddress = await airdropContract.methods.token().call();
      setTokenContractAddress(tokenAddress);
      const tokenSupply = await tokenContract.methods.totalSupply().call();
      setTotalSupply(tokenSupply);
      const tokenPrice = await airdropContract.methods.USDPerToken().call();
      setTokenPrice(convertPrice(tokenPrice, 18));
      const tx_allowted = await airdropContract.methods
      .totalTokensAllotedTillDate()
      .call();
    setTotalTokensAlloetwd(parseFloat(convertPrice(tx_allowted, 18)));
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getTokenData();
  }, []);

  function formatNumber(numberString) {
    const number = parseFloat(numberString);
    if (number >= 1000000000) {
      return (number / 1000000000).toFixed(0) + " B";
    } else if (number >= 1000000) {
      return (number / 1000000).toFixed(0) + " M";
    } else {
      return numberString;
    }
  }

  return (
    <>
      <Header />
      <section className="text-center pt-5">
        <Container>
          <Row className="d-flex justify-content-center align-items-center">
            <Col md={12} lg={8}>
              <h5 className="font-outfit fw-700 text-white text-capitalize">
                GKC Token
              </h5>
              <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                Revolutionizing the Future of Transactions: GKC Token - Fast,
                Secure, and Transparent
              </h2>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                GKC is developing as trust worthy and highly decentralised
                infrastructure for Medical, Education and Financial services, it
                hold the ideals of permission less access, security and
                immutability, all indispensable components for a future where
                anyone in the world can access and use the said services without
                fear of discrimination or counterparty risk. GKC will be highly
                acceptable utility tokens in all associated institutions.
              </p>
              {/* <Button
                variant="outline-secondary"
                className="btn-primary-custom"
              >
                Claim Token
              </Button>{" "} */}
            </Col>
          </Row>
        </Container>
      </section>
      <section className="sect-title-emplty-sec"></section>
      <section className="">
        <Container>
          <Row className="contracttokenadd p-3 m-0 rounded d-flex justify-content-between align-items-center">
            <Col>
              <h2 className="m-0 fs-6 fw-700 font-outfit fs-20 text-white">
                Token Contract Address
              </h2>
            </Col>
            <Col className="text-end">
              <a
                className="m-0 fs-22 fw-400 font-roboto text-white text-decoration-none"
                href={`${scanerUrl}/address/${tokenContractAddress}`}
                target="_blank"
              >
                {truncateEthAddress(tokenContractAddress)}
              </a>
              {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(tokenContractAddress)} />}
            </Col>
          </Row>
          <Row className="my-4">
            <Col md={12} lg={6} className="mb-4 mb-md-4 mb-lg-0">
              <Card className="serv-cards tokencardsg p-0">
                <Card.Body className="p-0">
                  <div className="gchead d-flex justify-content-between p-3">
                    <p className="m-0 fw-700 font-outfit fs-20 text-white">
                      Token Name:
                    </p>
                    <p className="m-0 fw-700 font-outfit fs-20 text-white">
                      GKC
                    </p>
                  </div>
                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      Initial Circulating Supply :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      {formatNumber(convertPrice(totalSupply, 18))}
                    </p>
                  </div>
                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      Initial Market Cap :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      {formatNumber(convertPrice(totalSupply * tokenPrice, 18))}
                    </p>
                  </div>
                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      Estimated Listing :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      Q4 2023
                    </p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="serv-cards tokencardsg p-3 p-md-5 mt-3">
                <Image src={GT1} className="img-fluid" alt="" />
              </Card>
            </Col>
            <Col md={12} lg={6}>
              <Card className="serv-cards tokencardsg p-0">
                <Card.Body className="p-0">
                  <div className="gchead d-flex justify-content-between p-3">
                    <p className="m-0 fw-700 font-outfit fs-20 text-white">
                      Token Type:
                    </p>
                    <p className="m-0 fw-700 font-outfit fs-20 text-white">
                      ERC20 (Polygon)
                    </p>
                  </div>

                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      GKC Token Price :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      {tokenPrice} USD
                    </p>
                  </div>
                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      Initial Market Cap after listing :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      2000.00 M
                    </p>
                  </div>
                  <div className="p-3 d-flex justify-content-between">
                    <p className="m-0 fw-400 font-roboto fs-18 text-logan">
                      Estimated Listing Price :
                    </p>
                    <p className="m-0 fw-400 font-roboto fs-18 text-white">
                      1.00 USD
                    </p>
                  </div>
                </Card.Body>
              </Card>
              <Card className="serv-cards tokencardsg p-2 mt-3">
                <Image src={GT2} className="img-fluid" alt="" />
              </Card>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="py-5">
        <Container>
          <Row className="d-flex justify-content-between align-items-center">
            <Col md={12} lg={6}>
              {/* <Card className="serv-cards tokencardsg p-2"> */}
              <Image src={GT3} className="img-fluid" alt="" />
              {/* </Card> */}
            </Col>
            <Col md={12} lg={6}>
              <Row>
                <Col sm={12} md={6} lg={6} className="">
                  <Card className="gpricecard text-center py-5">
                    <Card.Body className="py-5">
                      <Card.Title className="fs-25 font-outfit fw-700 text-white text-capitalize">
                        Price
                      </Card.Title>
                      <Card.Text className="fs-20 fw-400 font-roboto text-logan">
                        $ {tokenPrice}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6} className="mt-4 mt-md-5 mb-4">
                  <Card className="gpricecard text-center py-5">
                    <Card.Body className="py-5">
                      <Card.Title className="fs-25 font-outfit fw-700 text-white text-capitalize">
                        Market Cap
                      </Card.Title>
                      <Card.Text className="fs-20 fw-400 font-roboto text-logan">
                        ${" "}
                        {formatNumber(
                          convertPrice(totalSupply * tokenPrice, 18)
                        )}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
                <Col sm={12} md={6} lg={6} className="">
                  <Card className="gpricecard text-center py-5">
                    <Card.Body className="py-5">
                      <Card.Title className="fs-25 font-outfit fw-700 text-white text-capitalize">
                        Total Supply
                      </Card.Title>
                      <Card.Text className="fs-20 fw-400 font-roboto text-logan">
                        {formatNumber(convertPrice(totalSupply, 18))}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>

      <section className="pb-5">
        <Container>
          <Row>
            <Col>
              <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                airdrop status
              </h5>
            </Col>
          </Row>
          <Row className="d-flex justify-content-between align-items-center">
            <Col>
              <Card className="reffrealcoed p-4">
                <Card.Body className="p-0">
                  <Row className="d-flex justify-content-between align-items-center">
                    <Col md={6} className="text-center">
                      <Image src={Airdrop} alt="" className="img-fluid w-75" />
                    </Col>
                    <Col md={6}>
                      <div className="d-block justify-content-start align-items-center d-md-flex justify-content-md-start align-items-md-center mb-5">
                        <div className="pe-0 pe-md-5 border-end">
                          <h2 className="fw-500 font-roboto fs-30 text-white">
                            {10000000 - totalTokensAllowted}
                          </h2>
                          <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                            Airdrop Give Away GKC Tokens
                          </p>
                        </div>
                        <div className="ps-0 ps-md-5 mt-3 mt-md-0">
                          <h2 className="fw-500 font-roboto fs-30 text-white">
                            {"1,00,00,000"}
                          </h2>
                          <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                            Tokens Avaialbe in Airdrop for Round 1
                          </p>
                        </div>
                      </div>
                      <div>
                        <h6 className="text-white fw-500 fs-14 font-outfit">
                          Join and earn free airdrop:
                        </h6>
                        <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                          <li>
                            Signup to avail of Airdrop 100 GKC Token Worth 5.3
                            USD
                          </li>
                          <li>Connect your Wallet with the Polygon network</li>
                          <li>Claim your Daily Minted token from Dapp</li>
                        </ul>
                        <Link
                          to="/signup"
                          className="btn-primary-custom btn btn-outline-success mt-4"
                        >
                          Sign Up
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="pb-5">
          <Container className="p-5">
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  GKC Token Total Supply
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={100}
                  usdPrice={parseFloat(convertPrice(totalSupply, 18))}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Token balance
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={90}
                  usdPrice={90000000}
                />
                {/* <ProgressBarc
                  progressWidth={(
                    ((10000000 - totalTokensAllowted) * 100) /
                    10000000
                  ).toFixed(4)}
                  usdPrice={10000000 - totalTokensAllowted}
                /> */}
              </Col>
              
              <Col xs={12} className="py-4">
                <div className="border-bottom mt-3"></div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
                <Col xs={12} md={3} className="d-flex align-items-end">
                  <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                    Token for Airdrop Round 1
                  </p>
                </Col>
                <Col xs={12} md={9}>
                  <ProgressBarc
                    progressWidth={100}
                    usdPrice={10000000}
                  />
                  {/* <ProgressBarc
                  progressWidth={
                    1000000000 / parseFloat(convertPrice(totalSupply, 18))
                  }
                  usdPrice={10000000}
                /> */}
                </Col>
              </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Token has been Airdropped
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={(totalTokensAllowted * 100) / 10000000}
                  usdPrice={totalTokensAllowted}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Tokens Balance in Airdrop
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={(
                    ((10000000 - totalTokensAllowted) * 100) /
                    10000000
                  ).toFixed(4)}
                  usdPrice={10000000 - totalTokensAllowted}
                />
              </Col>
            </Row>
            {/* <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Total Distribution
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={100}
                  usdPrice={100000000}
                />
              </Col>
            </Row> */}
          </Container>
        </section>
      <Footer />
    </>
  );
};

export default GkcToken;

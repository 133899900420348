import React, { useEffect, useState } from "react";
import { Col, Container, Row, Image } from "react-bootstrap";
import Footer from "../layout/Footer";
import Header from "../layout/Header.";
import Hero1 from "../assets/images/hero1.png";
import Nft from "../assets/images/NFT_1.png";
import ServiceCard from "../components/home/ServiceCard";
import Airdrop from "../assets/images/airdrop.png";
import { Link } from "react-router-dom";
import RoadMap2 from "../components/home/RoadMap2";
import Web3 from "web3";
import airdropAbi from '../abis/airdropAbi.json'
import { AIRDROP_CONTRACT, convertPrice, loadContract, rpcUrl } from "../components/constant";

const Home = () => {
  const [totalTokensAllowted, setTotalTokensAlloetwd] = useState(0);

  const getTokenData = async () => {
    try {
      const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl));
      const airdropContract = loadContract(
        httpProvider,
        airdropAbi,
        AIRDROP_CONTRACT
      );

      const tx_allowted = await airdropContract.methods
        .totalTokensAllotedTillDate()
        .call();
      setTotalTokensAlloetwd(parseFloat(convertPrice(tx_allowted, 18)));
    } catch (error) {
    } finally {
    }
  };

  useEffect(() => {
    getTokenData();
  }, []);


  return (
    <>
      <div>
        <Header />

        <section className="main-hro-container">
          <section className="hero-banner pb-5">
            <Container>
              <Row className="d-flex justify-content-center align-items-center">
                <Col md={12} lg={6} className="order-1 order-md-1 order-lg-0">
                  <h1 className="display-2 fw-700 font-outfit mb-4">
                    Endless Possibilities with{" "}
                    <span className="d-block  gradient-color-one">
                      GKC Token
                    </span>
                  </h1>
                  {/* <Link
                    to="/"
                    variant="outline-secondary"
                    className="btn-primary-custom text-decoration-none d-inline-block ms-3"
                  >
                    Launch Dapp
                  </Link>{" "} */}
                  <Link
                    to="/gkc-token"
                    variant="outline-secondary"
                    className="btn-primary-custom text-decoration-none d-inline-block"
                  >
                    Know More
                  </Link>{" "}
                </Col>
                <Col md={12} lg={6} className="order-0 order-md-0 order-lg-1">
                  <Image
                    src={Hero1}
                    alt=""
                    className="img-fluid mt-5 mt-md-5 mt-lg-0"
                  />
                </Col>
              </Row>
            </Container>
          </section>

          <section className="home-about">
            <Container>
              <Row className="d-flex justify-content-center align-items-center mx-0 mx-md-5">
                <Col className="p-3 p-md-5 home-about-inner">
                  <Row className="d-flex justify-content-center align-items-center">
                    <Col md={12} lg={5}>
                      <Image src={Nft} alt="" className="img-fluid" />
                    </Col>
                    <Col md={12} lg={7}>
                      <h5 className="font-outfit fw-700 text-white text-capitalize">
                        About
                      </h5>
                      <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                        Using blockchain technology, Smart Contracts, and our
                        cryptocurrency, GKC Provides
                      </h2>
                      <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                        The infrastructure for digital health applications and
                        services to be built. These applications and services
                        will be seamlessly powered by the user’s health data.
                        Anyone will be able to develop on GKC ’s platform and we
                        hope to foster a thriving ecosystem to provide value,
                        reduce costs, and ultimately improve people’s lives.
                      </p>
                      <Link
                        to="/about"
                        variant="outline-secondary"
                        className="btn-primary-custom text-decoration-none d-inline-block"
                      >
                        Know More
                      </Link>{" "}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </section>
        </section>
        <section className="sect-title-emplty-sec"></section>
        <section className="home-service-sec">
          <Container>
            <Row>
              <Col className="text-center mb-4">
                <h3 className="font-outfit fw-700 text-white text-capitalize">
                  Services
                </h3>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-items-center">
              <ServiceCard />
            </Row>
          </Container>
        </section>

        <section className="py-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col md={12} lg={6} className="pe-3 pe-md-5">
                <h5 className="font-outfit fw-700 text-white text-capitalize">
                  airdrop
                </h5>
                <h2 className="font-outfit fw-700 text-white my-3 my-md-3">
                  "Join our Airdrop and Get Free Tokens - Limited Time Offer!"
                </h2>
                <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify mb-4">
                  Welcome to our airdrop program! We're excited to announce that
                  we're giving away free tokens to our loyal community members
                  as a way of saying thank you for your continued support.
                </p>
                {/* <Button
                  variant="outline-secondary"
                  className="btn-primary-custom"
                >
                  Know More
                </Button>{" "} */}
                <Row className="d-flex justify-content-between align-items-center">
                  <Col xs={12}>
                    <div className="d-block justify-content-start align-items-center d-md-flex justify-content-md-start align-items-md-center mb-5">
                      <div className="pe-0 pe-md-5 border-end">
                        <h2 className="fw-500 font-roboto fs-30 text-white">
                          {10000000 - totalTokensAllowted}
                        </h2>
                        <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                          Airdrop Give Away GKC Tokens
                        </p>
                      </div>
                      <div className="ps-0 ps-md-5 mt-3 mt-md-0">
                        <h2 className="fw-500 font-roboto fs-30 text-white">
                          {"1,00,00,000"}
                        </h2>
                        <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                          Tokens Avaialbe in Airdrop for Round 1
                        </p>
                      </div>
                    </div>
                    <div>
                      <h6 className="text-white fw-500 fs-14 font-outfit">
                        Join and earn free airdrop:
                      </h6>
                      <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                        <li>
                          Signup to avail of Airdrop 100 GKC Token Worth 5.3 USD
                        </li>
                        <li>Connect your Wallet with the Polygon network</li>
                        <li>Claim your Daily Minted token from Dapp</li>
                      </ul>
                      <Link
                        to="/signup"
                        className="btn-primary-custom btn btn-outline-success mt-4"
                      >
                        Sign Up
                      </Link>
                    </div>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={6} className="text-center">
                <Image src={Airdrop} alt="" className="img-fluid" />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="home-roadmap-sec pt-5">
          <Container>
            <Row className="d-flex justify-content-center align-items-center">
              <Col xs={12} md={11}>
                <h5 className="font-outfit fw-700 text-white text-capitalize text-center mb-4">
                  Roadmap
                </h5>
                <p className="font-roboto text-AmethystSmoke fs-18 fw-400 text-justify pb-5 ">
                  Decentralised infrastructure or services is the future. GKC
                  protocol is launching after extensive research done by experts
                  of Blockchain technologies. It will keep on evolving as per
                  the one year roadmap. We will provide next level development
                  after listing of GKC tokens, this will be decentralised
                  infrastructure Blockchain in coming period.{" "}
                </p>
              </Col>
              <Col xs={12} md={11}>
                <div className="home-road-contnr">
                  {/* <Roadmap /> */}
                  <RoadMap2 />
                </div>
              </Col>
              {/* <Col xx={12} md={12}>
                <RoadmapTwo />
              </Col> */}
            </Row>
          </Container>
        </section>
        <Footer />
      </div>
    </>
  );
};

export default Home;

import React from "react";
import { Container, Row, Col, Nav, Image } from "react-bootstrap";
import Logo from "../assets/images/gkc-Logo_text.png";
import { FaTelegramPlane, FaFacebookF } from "react-icons/fa";
import { BsInstagram, BsTwitter } from "react-icons/bs";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <>
      <footer className="text-center">
        <section className="footer-top py-5">
          <Container>
            <Row>
              <Col xs={12}>
                <Image src={Logo} alt="Logo" className="img-fluid" />
                <p className="mt-4 fs-22 fw-700 font-outfit text-capitalize">
                  Join our community{" "}
                  <a
                    href="mailto:info@gkctech.io"
                    target="blank"
                    className="mt-4 fs-22 fw-400 font-outfit text-logan text-decoration-none text-lowercase"
                  >
                    info@gkctech.io
                  </a>
                </p>
              </Col>
              <Col xs={12}>
                <Nav className="justify-content-center">
                  <Link
                    className="nav-link"
                    target="blank"
                    to="https://t.me/gkctech"
                  >
                    <FaTelegramPlane className="text-logan" size={20} />
                  </Link>
                  <Link
                    className="nav-link"
                    target="blank"
                    to="https://www.facebook.com/people/GKC-Technologies/100092255200752/"
                  >
                    <FaFacebookF className="text-logan" size={20} />
                  </Link>
                  <Link
                    className="nav-link"
                    target="blank"
                    to="https://www.instagram.com/gkc_tech.io/"
                  >
                    <BsInstagram className="text-logan" size={20} />
                  </Link>
                  <Link
                    className="nav-link"
                    target="blank"
                    to="https://twitter.com/Gkctechio"
                  >
                    <BsTwitter className="text-logan" size={20} />
                  </Link>
                </Nav>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="footer-bottom py-5">
          <Container>
            <Row>
              <Col>
                <p className="m-0 fs-16 font-outfit fw-400 text-logan">
                  Global Knowledge Center | All rights reserved 2023
                </p>
              </Col>
            </Row>
          </Container>
        </section>
      </footer>
    </>
  );
};

export default Footer;

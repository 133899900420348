import Web3 from 'web3'

// export const ROOT_URL = 'http://192.168.0.187:6969';
export const ROOT_URL = 'https://backend.gkctech.io';
export const rpcUrl = 'https://polygon-rpc.com'
export const scanerUrl = 'https://polygonscan.com'

const url = new URL(window.location.href);
const baseUrl = url.port ? `${url.protocol}//${url.hostname}:${url.port}` : `${url.protocol}//${url.hostname}`
export const REF_URL = `${baseUrl}/signup?ref=`;
export const DOMAIN_URL = "https://gkctech.io";

export const AIRDROP_CONTRACT = '0x6a22f9d2c716d9f10d1bbeb9697fa67785e5673b';
export const TOKEN_CONTRACT = '0x0Ad3a144685F1E43371072418A9478cB5d926bd3';
export const ADMIN_ADDRESS = '0x000000000000000000000000000000000000dEaD';

export const getShortAddress = (address) => {
    return address.substr(0, 5) + '...' + address.substr(address.length - 5)
}

export const loadContract = (provider, abi, address) => {
    const w3 = new Web3(provider)
    return new w3.eth.Contract(abi, address)
}

export const convertPrice = (value, decimal) => {
    const price = (value / 10 ** decimal).toLocaleString("fullwide", { useGrouping: false });
    return price;
}

export const convertMultiPrice = (value, decimal) => {
    const price = (value * 10 ** decimal).toLocaleString("fullwide", { useGrouping: false });
    return price;
}

export const DECIMALS = 10 ** 18
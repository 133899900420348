import React from "react";

const RoadMap2 = () => {
  return (
    <>
      <section className="roadmap-two">
        <div className="roadttimeline">
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                January 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Research started by the team On decentralised infrastructure.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                April 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Conceptualisation of the project.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                July 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Team will be execute platform and services.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-right">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                September 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Community development and Airdrop will be completed.
              </p>
            </div>
          </div>
          <div className="roadmaincont roadm-left">
            <div className="content">
              <h5 className="fw-700 text-white font-outfit text-uppercase mb-3">
                December 2023
              </h5>
              <p className="font-roboto text-AmethystSmoke fs-18 fw-400 mb-0">
                Listing of GKC tokens worldwide in many exchanges.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoadMap2;

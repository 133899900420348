import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import UserAuthLayout from "../layout/UserAuthLayout";
import logo from "../../assets/images/GKC_Logo.png";
import axios from "axios";
import { MessageBox } from "../../components/MessageBox";
import {
  ADMIN_ADDRESS,
  AIRDROP_CONTRACT,
  ROOT_URL,
  TOKEN_CONTRACT,
  loadContract,
} from "../../components/constant";
import useAuth from "../../components/connectWallet/useAuth";
import { injected } from "../../components/connectWallet/connectors";
import { useWeb3React } from "@web3-react/core";
import airdropAbi from "../../abis/airdropAbi.json";
import tokenAbi from "../../abis/tokenAbi.json";
import WalletModal from "../../components/WalletModal";
import { AiFillHome } from "react-icons/ai";
import { TbArrowBackUp } from "react-icons/tb";
const SignUp = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [refAddress, setRefAddress] = useState("");
  const [validation, setValidation] = useState({
    name: false,
    email: false,
    mobile: false,
    pwd1: false,
    pwd2: false,
  });
  const [loading, setLoading] = useState(false);
  const [edit, setEdit] = useState(false);
  const [open, setOpen] = useState(false);

  const navigate = useNavigate();
  const { active, account, library } = useWeb3React();
  // const { login } = useAuth();

  useEffect(() => {
    // set REF code
    const ref_adrs = window.location.href.split("signup?ref=")[1]; // if URL contains REF code
    if (ref_adrs) {
      setRefAddress(ref_adrs);
    }
  }, []);

  const checkValidation = () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet");
      return;
    }
    if (loading) {
      return;
    }
    if (!name) {
      setValidation({ ...validation, name: true });
      return;
    }
    if (!/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w+)+$/.test(email)) {
      setValidation({ ...validation, email: true });
      return;
    }
    if (!/^[0-9]{5,11}$/.test(mobile)) {
      setValidation({ ...validation, mobile: true });
      return;
    }
    if (
      !/(?=^.{8,}$)(?=.*\d)(?=.*[!@#$%^&*]+)(?![.\n])(?=.*[A-Z])(?=.*[a-z]).*$/.test(
        password
      )
    ) {
      setValidation({ ...validation, pwd1: true });
      return;
    }
    if (password != confirmPassword) {
      setValidation({ ...validation, pwd2: true });
      return;
    }
    onSignUp();
  };

  const contractSignup = async () => {
    try {
      const airdropContract = loadContract(
        library.provider,
        airdropAbi,
        AIRDROP_CONTRACT
      );
      const txAirdrop = airdropContract.methods
        .airdrop(account, refAddress ? refAddress : ADMIN_ADDRESS)
        .send({ from: account })
        .on("transactionHash", (transactionHash) => {
          axios({
            method: "post",
            url: `${ROOT_URL}/Register`,
            data: {
              name: name,
              email: email,
              mobile: mobile,
              password: password,
              refaddess: refAddress ? refAddress : ADMIN_ADDRESS,
              walletaddress: account ? account : "",
              transactionHash: transactionHash,
            },
          })
            .then((res) => {
              setLoading(false);
              if (res.data.code == 200) {
                MessageBox("success", res.data.message);
                navigate("/signin");
              } else {
                MessageBox("error", res.data.message);
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log("Error.signup", err);
            });
        });
    } catch (e) {
      setLoading(false);
      MessageBox("error", e.message);
      console.log("Error.Contract-signiup", e);
    }
  };

  const onSignUp = async () => {
    setLoading(true);
    const res_check_user = await axios
      .post(`${ROOT_URL}/Check_User`, { walletaddress: account, email: email })
      .catch((err) => {
        console.log("err.check_user", err);
        setLoading(false);
      });
    if (!res_check_user) {
      return;
    }
    if (res_check_user.data.code != 200) {
      setLoading(false);
      MessageBox("error", res_check_user.data.message);
      return;
    }
    contractSignup();
  };

  return (
    <>
      <UserAuthLayout>
        <section className="auth-main">
          <Container>
            <Row className="d-flex justify-content-center align-items-center text-center pb-5">
              <Col md={7} lg={5}>
                <div className="sign-card p-5 rounded mt-5">
                  <Link to="/">
                    <Image src={logo} alt="" className="img-fluid mb-4" />
                  </Link>

                  <h2 className="font-outfit fw-700 text-white text-capitalize mb-4">
                    Sign Up
                  </h2>
                  <p className="font-roboto fw-400 text-logan fs-18 mb-3">
                    Enter your details to sign up
                  </p>
                  <Form>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Full Name"
                        value={name}
                        onChange={(event) => {
                          setName(event.target.value);
                          setValidation({ ...validation, name: false });
                        }}
                      />
                      {validation.name && (
                        <label className="form-label m-0 text-danger">
                          Please enter full name
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="email"
                        placeholder="Email address"
                        value={email}
                        onChange={(event) => {
                          setEmail(event.target.value);
                          setValidation({ ...validation, email: false });
                        }}
                      />
                      {validation.email && (
                        <label className="form-label m-0 text-danger">
                          {email
                            ? "Invalid email address"
                            : "Please enter email address"}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Mobile Number"
                        value={mobile}
                        onChange={(event) => {
                          if (/^[0-9]{0,11}$/.test(event.target.value)) {
                            setMobile(event.target.value);
                            setValidation({ ...validation, mobile: false });
                          }
                        }}
                      />
                      {validation.mobile && (
                        <label className="form-label m-0 text-danger">
                          {mobile
                            ? "Invalid mobile number"
                            : "Please enter mobile number"}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Password"
                        value={password}
                        onChange={(event) => {
                          setPassword(event.target.value);
                          setValidation({ ...validation, pwd1: false });
                        }}
                      />
                      {validation.pwd1 && (
                        <label className="form-label m-0 text-danger">
                          {password
                            ? "Please enter strong password"
                            : "Please enter password"}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="password"
                        placeholder="Conform Password"
                        value={confirmPassword}
                        onChange={(event) => {
                          setConfirmPassword(event.target.value);
                          setValidation({ ...validation, pwd2: false });
                        }}
                      />
                      {validation.pwd2 && (
                        <label className="form-label m-0 text-danger">
                          {validation.pwd2 && confirmPassword
                            ? "Password does not match"
                            : "Please enter confirm password"}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Control
                        type="text"
                        placeholder="Reference Address"
                        value={refAddress}
                        // onChange={(e) => setRefAddress(e.target.value)}
                        disabled={!edit}
                      />
                      {/* <button onClick={(e) => {
                        e.preventDefault()
                        setEdit(!edit)
                      }}>Edit</button> */}
                    </Form.Group>
                    <Button
                      variant="outline-success"
                      size="lg"
                      className="btn-primary-custom w-100 py-3 mb-3"
                      onClick={() => {
                        if (account) {
                          checkValidation();
                        } else {
                          setOpen(true);
                        }
                      }}
                    >
                      {loading
                        ? "Loading..."
                        : account
                        ? "Sign Up"
                        : "Connect Wallet"}
                    </Button>
                  </Form>
                  <div>
                    <p className="font-roboto fw-400 text-logan fs-18">
                      Allready have an account?{" "}
                      <Link
                        to="/signin"
                        className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                      >
                        Sign In
                      </Link>
                    </p>
                    <Link
                      to="/sent-otp"
                      className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                    >
                      Forgot Password?
                    </Link>
                  </div>
                  <div className="mt-3">
                    <Link
                      to="/"
                      className="font-roboto fw-400 text-logan fs-18 text-decoration-none"
                    >
                      <TbArrowBackUp /> Back To Home <AiFillHome />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
          <WalletModal open={open} setOpen={setOpen} />
        </section>
      </UserAuthLayout>
    </>
  );
};

export default SignUp;

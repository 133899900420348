import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Image,
  InputGroup,
  Button,
  Form,
  Table,
  Collapse,
} from "react-bootstrap";
import DataCard from "../components/DataCard";
import TotalDataCards from "../components/TotalDataCards";
import UserDashBoardLayout from "../layout/UserDashBoardLayout";
import Poly from "../../assets/images/polygon.png";
import Airdrop from "../../assets/images/airdrop.png";
import { TbArrowsUpDown } from "react-icons/tb";
import { useWeb3React } from "@web3-react/core";
import Web3 from "web3";
import airdropAbi from "../../abis/airdropAbi.json";
import tokenAbi from "../../abis/tokenAbi.json";
import { MessageBox } from "../../components/MessageBox";
import {
  ADMIN_ADDRESS,
  AIRDROP_CONTRACT,
  DOMAIN_URL,
  REF_URL,
  ROOT_URL,
  TOKEN_CONTRACT,
  convertMultiPrice,
  convertPrice,
  getShortAddress,
  loadContract,
  rpcUrl,
  scanerUrl,
} from "../../components/constant";
import { BiCopy } from "react-icons/bi";
import { BsFacebook, BsTelegram, BsTwitter, BsWhatsapp } from "react-icons/bs";
import useCopy from "../../useCopy";
import {
  FacebookIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
} from "react-share";
import ProgressBarc from "../../components/ProgressBarc";
import truncateEthAddress from "truncate-eth-address";
import axios from "axios";

const Dashboard = () => {
  const [balance, setBalance] = useState({ matic: 0, token: 0 });
  const [tokensPerMatic, setTokensPerMatic] = useState(0);
  const { account, active, library } = useWeb3React();
  const [claimedTokens, setClaimedTokens] = useState(0);
  const [purchasedTokens, setPurchasedTokens] = useState(0);
  const [userReffral, setUserRafferal] = useState("");
  const [loadingClaim, setLoadingClaim] = useState(false);
  const [contentLoader, setContentLoader] = useState(false);
  const [contentLoader2, setContentLoader2] = useState(false);
  const [tokenContractAddress, setTokenContractAddress] = useState("");
  const [totalTokenAirdrop, setTotalTokenAirdrop] = useState(0);
  const [tokenAvailableForMinting, setTokenAvailableForMinting] = useState(0);
  const [userLevelOneEarning, setUserLevelOneEarning] = useState(0);
  const [userLevelTwoEarning, setUserLevelTwoEarning] = useState(0);
  const { copyText, copied } = useCopy();
  const [totalTokensAllowted, setTotalTokensAlloetwd] = useState(0);
  const [totalSupply, setTotalSupply] = useState(0);
  const [directRecieved, setDirectRecived] = useState(0)

  const getDashBoardDataWithOutWallet = async () => {
    setContentLoader2(true);
    const httpProvider = new Web3(new Web3.providers.HttpProvider(rpcUrl));
    try {
      const airdropContract = loadContract(
        httpProvider,
        airdropAbi,
        AIRDROP_CONTRACT
      );
      const tokenContract = loadContract(
        httpProvider,
        tokenAbi,
        TOKEN_CONTRACT
      );
      const tokenContractAddress = await airdropContract.methods.token().call();
      setTokenContractAddress(tokenContractAddress);
      const tx_permatic = await airdropContract.methods.USDPerToken().call();
      setTokensPerMatic(parseFloat(convertPrice(tx_permatic, 18)));
      const tx_allowted = await airdropContract.methods
        .totalTokensAllotedTillDate()
        .call();
      setTotalTokensAlloetwd(parseFloat(convertPrice(tx_allowted, 18)));
      const tokenSupply = await tokenContract.methods.totalSupply().call();
      setTotalSupply(tokenSupply);
    } catch (error) {
      console.log(error);
      setContentLoader2(false);
    } finally {
      setContentLoader2(false);
    }
  };

  useEffect(() => {
    getDashBoardDataWithOutWallet();
  }, []);

  const getDashBoardData = async () => {
    setContentLoader(true);
    const w3 = new Web3(library.provider);
    try {
      if (
        account.toLocaleLowerCase() ==
        sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
      ) {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        );
        const tokenContract = loadContract(
          library.provider,
          tokenAbi,
          TOKEN_CONTRACT
        );
        const tx_claimed = await airdropContract.methods
          .totalClaimedTokens()
          .call();
        setClaimedTokens(tx_claimed);

        const tx_airdrop = await airdropContract.methods
          .usersTotalTokenAirdrop(account)
          .call();
        setTotalTokenAirdrop(convertPrice(tx_airdrop, 18));

        const tx_ref = await airdropContract.methods
          .userReferrer(account)
          .call();
        setUserRafferal(tx_ref);

        const tx_availableforminting = await airdropContract.methods
          .getUserTokensAvailableForMinting(account)
          .call();
        setTokenAvailableForMinting(convertPrice(tx_availableforminting, 18));

        const tx_level1earning = await airdropContract.methods
          .usersLevelOneReferralEarning(account)
          .call();
        setUserLevelOneEarning(convertPrice(tx_level1earning, 18));

        const tx_level2earning = await airdropContract.methods
          .usersLevelTwoReferralEarning(account)
          .call();
        setUserLevelTwoEarning(convertPrice(tx_level2earning, 18));

        const totaltokenpurchesed = await airdropContract.methods
          .usersTotalTokenPurchased(account)
          .call();
        setPurchasedTokens(convertPrice(totaltokenpurchesed, 18));

        const maticBal = await w3.eth.getBalance(account);
        const txToken_balance = await tokenContract.methods
          .balanceOf(account)
          .call();
        setBalance({
          matic: convertPrice(maticBal, 18),
          token: convertPrice(txToken_balance, 18),
        });
      }
      setContentLoader(false);
    } catch (e) {
      console.log("Error.Contract-token", e);
      setContentLoader(false);
    } finally {
      setContentLoader(false);
    }
  };

  const getDirectRecieved = () => {
    if (
      account.toLocaleLowerCase() ==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      axios({
        method: 'post',
        url: `${ROOT_URL}/Get_Direct_Recieved_Amount`,
        data: {
          walletaddress: sessionStorage.getItem('walletaddress') || '',
        }
      }).then(res => {
        if (res.data.code == 200) {
          setDirectRecived(res.data.data.amount)
        }
      }).catch(err => {
        console.log('Error.direct-recived', err);
      });
    }
  }

  useEffect(() => {
    if (account) {
      getDashBoardData();
      getDirectRecieved()
    }
  }, [account]);

  const onClaimToken = async () => {
    if (!account) {
      MessageBox("error", "Please Connect Your Wallet");
      return;
    }
    if (
      account.toLocaleLowerCase() !==
      sessionStorage.getItem("walletaddress")?.toLocaleLowerCase()
    ) {
      MessageBox(
        "error",
        `Wrong address please connect ${sessionStorage.getItem(
          "walletaddress"
        )}`
      );
      return;
    }
    if (loadingClaim) {
      return;
    }
    if (tokenAvailableForMinting > 0) {
      setLoadingClaim(true);
      try {
        const airdropContract = loadContract(
          library.provider,
          airdropAbi,
          AIRDROP_CONTRACT
        );
        const txAirdrop_claimed = await airdropContract.methods
          .claimTokens()
          .send({ from: account });
        getDashBoardData();
        setLoadingClaim(false);
        MessageBox("success", "Token Claimed Successfully");
      } catch (e) {
        setLoadingClaim(false);
        MessageBox("error", e.message);
        console.log("Error.claim-token-contract", e);
      }
    } else {
      MessageBox("error", "No Tokens are available for claim");
    }
  };

  return (
    <>
      <UserDashBoardLayout>
        <section className="mt-5">
          <Container>
            <Row>
              <Col>
                <h1 className="fs-12 fw-500 font-roboto text-white text-uppercase mb-3">
                  gkc token
                </h1>
              </Col>
            </Row>
            <Row className="tokenaddet p-3 m-0 border rounded d-flex justify-content-between align-items-center">
              <Col className="text-center text-md-start">
                <h2 className="m-0 fs-17 font-roboto fw-500 text-white">
                  GKC Token Contract Address
                </h2>
              </Col>
              <Col className="text-center text-md-end">
                <a
                  className="m-0 fs-14 font-roboto fw-400 text-white text-decoration-none"
                  href={`${scanerUrl}/address/${tokenContractAddress}`}
                  target="_blank"
                >
                  {/* {tokenContractAddress} */}
                  {truncateEthAddress(tokenContractAddress)}
                </a>
                {
                  <BiCopy
                    style={{ cursor: "pointer", marginLeft: "3px" }}
                    onClick={() => copyText(tokenContractAddress)}
                  />
                }
              </Col>
            </Row>
          </Container>
        </section>
        <section className="py-5">
          <Container>
            <Row>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={
                    contentLoader2 ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      "$ " + tokensPerMatic
                    )
                  }
                  subtitle={"Price Of GKC Token"}
                />
              </Col>
              {/* <Col xs={12} md={4}>
                <DataCard
                  Img={Poly}
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      claimedTokens
                    )
                  }
                  subtitle={"Total Tokens Minting"}
                />
              </Col> */}
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      totalTokenAirdrop + " GKC"
                    )
                  }
                  subtitle={"Total Token Airdrop"}
                />
              </Col>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : (
                      purchasedTokens + " GKC"
                    )
                  }
                  subtitle={"Total Tokens Purchased"}
                />
              </Col>
            </Row>
            <Row>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={
                    contentLoader ? (
                      <span className="loading">Loading...</span>
                    ) : userReffral ? (
                      <span>{getShortAddress(userReffral)} {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(userReffral)} />}</span>
                    ) : (
                      "0x0"
                    )
                  }
                  subtitle={"Referral By"}
                />
              </Col>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={contentLoader ? (
                    <span className="loading">Loading...</span>
                  ) :
                    parseFloat(totalTokenAirdrop) +
                    parseFloat(purchasedTokens) +
                    parseFloat(userLevelOneEarning) +
                    parseFloat(userLevelTwoEarning) + " GKC"
                  }
                  subtitle={"Total GKC Token Allocated"}
                />
              </Col>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={contentLoader ? (
                    <span className="loading">Loading...</span>
                  ) : tokensPerMatic * balance.token + " GKC"}
                  subtitle={"GKC Token Cap"}
                />
              </Col>
            </Row>

            <Row>
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={contentLoader ? (
                    <span className="loading">Loading...</span>
                  ) : convertPrice(directRecieved, 18) + " GKC"}
                  subtitle={"Direct Received"}
                />
              </Col>
              {/* <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard subtitle={"Total Token Minted"} />
              </Col> */}
              <Col xs={12} md={4} className="mb-3 mb-md-3">
                <DataCard
                  title={contentLoader ? (
                    <span className="loading">Loading...</span>
                  ) :
                    <>{getShortAddress(sessionStorage.getItem('walletaddress'))} {<BiCopy style={{ cursor: "pointer" }} onClick={() => copyText(sessionStorage.getItem('walletaddress'))} />}</>}
                  subtitle={"Registered Wallet Address"}
                />
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container>
            <Row>
              <Col md={6} className="mb-3 mb-md-0">
                <Card className="data-cards p-0">
                  <Card.Body className="p-4 text-center">
                    <Table striped hover className="mb-0">
                      <thead>
                        <tr className="border-0">
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Refer Level 1
                          </th>
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Refer Level 2
                          </th>
                          <th className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3 border-0">
                            Total Refer Earning
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr className="border-0">
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {userLevelOneEarning}
                          </td>
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {userLevelTwoEarning}
                          </td>
                          <td className="text-capitalize font-roboto fw-400 fs-17 text-white border-0">
                            {parseFloat(userLevelOneEarning) +
                              parseFloat(userLevelTwoEarning)}
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </Card.Body>
                </Card>
              </Col>
              {/* total token minted */}
              <Col md={6}>
                <Card className="data-cards p-0">
                  <Card.Body className="p-4 text-center">
                    <Card.Title className="text-capitalize font-roboto fw-400 fs-17 text-white">
                      {contentLoader ? (
                        <span className="loading">Loading...</span>
                      ) : (
                        tokenAvailableForMinting + " GKC"
                      )}
                    </Card.Title>
                    <Card.Text className="font-roboto text-AmethystSmoke fs-13 fw-400 mt-3">
                      Token Minted
                    </Card.Text>
                    <Button
                      onClick={() => onClaimToken()}
                      // aria-controls="colap-one"
                      // aria-expanded={open}
                      className="font-roboto text-black fs-12 fw-400 btn btn-light rounded-pill"
                    >
                      Withdraw
                    </Button>
                    {/* <Collapse in={open}>
                      <div id="colap-one" className="bg-black p-3 rounded mt-3">
                        <InputGroup className="mb-0">
                          <Form.Control
                            placeholder="0.0000"
                            aria-label="0.0000"
                          />
                          <Button variant="outline-secondary" className="px-4">
                            Transfer
                          </Button>
                        </InputGroup>
                      </div>
                    </Collapse> */}
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>
        <section className="pb-5">
          <Container>
            <Row>
              <Col>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  referral code
                </h5>
              </Col>
            </Row>
            <Row className="reffrealcoed p-3 m-0 d-flex justify-content-between align-items-center">
              <Col className="text-center text-md-stat">
                <h2 className="m-0 fs-11 font-roboto fw-400 text-logan mb-3 mb-md-0">
                  Buy GKC for referral code
                </h2>
              </Col>
              <Col className="text-center text-md-end">
                {account ? (
                  account.toLocaleLowerCase() ===
                    sessionStorage
                      .getItem("walletaddress")
                      ?.toLocaleLowerCase() ? (
                    <p className="m-0 fs-11 font-roboto fw-400 text-white">
                      {`${REF_URL}${account}`}
                      {
                        <BiCopy
                          style={{ cursor: "pointer" }}
                          onClick={() => copyText(`${REF_URL}${account}`)}
                          size={20}
                          className="px-1 text-PictonBlue"
                        />
                      }
                      <a
                        href={`https://web.whatsapp.com/send?text=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsWhatsapp />
                      </a>
                      <a
                        href={`https://telegram.me/share/url?url=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsTelegram />
                      </a>
                      <a
                        href={`http://www.twitter.com/share?url=${REF_URL}${account}`}
                        target={"_blank"}
                        className="nav-link d-inline-block px-1 text-PictonBlue"
                      >
                        <BsTwitter />
                      </a>
                      <a className="nav-link d-inline-block px-1 text-PictonBlue">
                        <FacebookMessengerShareButton
                          appId="521270401588372"
                          quote={`${REF_URL}${account}`}
                          url={DOMAIN_URL}
                        >
                          <BsFacebook />
                        </FacebookMessengerShareButton>
                      </a>
                    </p>
                  ) : (
                    <span className="text-danger">{`Wrong address please connect ${getShortAddress(
                      sessionStorage.getItem("walletaddress")
                    )}`}</span>
                  )
                ) : (
                  "Please connect your wallet to get your REF URL"
                )}
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container>
            <Row>
              <Col>
                <h5 className="fs-12 fw-500 font-roboto text-logan text-uppercase mb-3">
                  airdrop status
                </h5>
              </Col>
            </Row>
            <Row className="d-flex justify-content-between align-items-center">
              <Col>
                <Card className="reffrealcoed p-4">
                  <Card.Body className="p-0">
                    <Row className="d-flex justify-content-between align-items-center">
                      <Col md={6} className="text-center">
                        <Image
                          src={Airdrop}
                          alt=""
                          className="img-fluid w-75"
                        />
                      </Col>
                      <Col md={6}>
                        <div className="d-block justify-content-start align-items-center d-md-flex justify-content-md-start align-items-md-center mb-5">
                          <div className="pe-0 pe-md-5 border-end">
                            <h2 className="fw-500 font-roboto fs-30 text-white">
                              {10000000 - totalTokensAllowted}
                            </h2>
                            <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                              Airdrop Give Away GKC Tokens
                            </p>
                          </div>
                          <div className="ps-0 ps-md-5 mt-3 mt-md-0">
                            <h2 className="fw-500 font-roboto fs-30 text-white">
                              {"1,00,00,000"}
                            </h2>
                            <p className="mb-0 fw-500 fs-17 font-roboto text-logan">
                              Tokens Avaialbe in Airdrop for Round 1
                            </p>
                          </div>
                        </div>
                        <div>
                          <h6 className="text-white fw-500 fs-14 font-outfit">
                            Join and earn free airdrop:
                          </h6>
                          <ul className="text-logan fw-400 fs-11 font-roboto ps-3 mb-0">
                            <li>
                              Signup to avail of Airdrop 100 GKC Token Worth 5.3
                              USD
                            </li>
                            <li>
                              Connect your Wallet with the Polygon network
                            </li>
                            <li>Claim your Daily Minted token from Dapp</li>
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Container>
        </section>

        <section className="pb-5">
          <Container className="p-5">
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  GKC Token Total Supply
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={100}
                  usdPrice={parseFloat(convertPrice(totalSupply, 18))}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Token balance
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={90}
                  usdPrice={90000000}
                />
                {/* <ProgressBarc
                  progressWidth={(
                    ((10000000 - totalTokensAllowted) * 100) /
                    10000000
                  ).toFixed(4)}
                  usdPrice={10000000 - totalTokensAllowted}
                /> */}
              </Col>

              <Col xs={12} className="py-4">
                <div className="border-bottom mt-3"></div>
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Token for Airdrop Round 1
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={100}
                  usdPrice={10000000}
                />
                {/* <ProgressBarc
                  progressWidth={
                    1000000000 / parseFloat(convertPrice(totalSupply, 18))
                  }
                  usdPrice={10000000}
                /> */}
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Token has been Airdropped
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={(totalTokensAllowted * 100) / 10000000}
                  usdPrice={totalTokensAllowted}
                />
              </Col>
            </Row>
            <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Tokens Balance in Airdrop
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={(
                    ((10000000 - totalTokensAllowted) * 100) /
                    10000000
                  ).toFixed(4)}
                  usdPrice={10000000 - totalTokensAllowted}
                />
              </Col>
            </Row>
            {/* <Row className="d-flex justify-content-center align-content-center">
              <Col xs={12} md={3} className="d-flex align-items-end">
                <p className="font-roboto fw-400 fs-14 text-logan mb-3 mb-md-0">
                  Total Distribution
                </p>
              </Col>
              <Col xs={12} md={9}>
                <ProgressBarc
                  progressWidth={100}
                  usdPrice={100000000}
                />
              </Col>
            </Row> */}
          </Container>
        </section>
      </UserDashBoardLayout>
    </>
  );
};

export default Dashboard;
